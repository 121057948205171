import { inject } from '@angular/core';

import { ShopInShopApi, Vendor } from '@idealsupply/ngclient-webservice-shop-in-shop';
import { signalStore } from '@ngrx/signals';
import { map } from 'rxjs';

import { withSearchResults } from 'state-data';

export const VendorShopSearchStore = signalStore(
  { providedIn: 'root' },
  withSearchResults<{}, Vendor>({}, () => {
    const api = inject(ShopInShopApi);
    return () => {
      return api.getShopInShopVendors().pipe(
        map((vendors) => {
          return {
            skip: 0,
            limit: vendors.length,
            total: vendors.length,
            data: vendors,
          };
        }),
      );
    };
  }),
);
